
import { defineComponent, onMounted } from 'vue';
import store from '@/store';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup: () => {
    const $router = useRouter();
    onMounted(async () => {
      await store.auth.logout();
      $router.push('/');
    });
    return {};
  },
});
